
import { contains, Get_Access_Token, Parse_Backend_Error } from "./functions";
import { Error } from "./classes";





const TIMEOUT: number = 25000;

export interface InvokerParameters {
	readonly method: string;
	readonly url: URL;
	readonly body?: any;
};

interface URLParameters {
	readonly path?: Record<string, string | number>;
	readonly query?: Record<string, any>;
};

export const APIURL = (path: string, parameters?: URLParameters, base?: string): URL => {
	const url: URL = new URL((contains(base)? base : "/api/app") + path, window.location.origin);
	if (parameters) {
		if (parameters.query) {
			for (const k in parameters.query) {
				if (parameters.query[k].constructor === Array) {
					for (const i of parameters.query[k]) url.searchParams.append(`${k}[]`, i);
				} else url.searchParams.append(k, parameters.query[k]);
			}
		}
	}
	return url;
};





const InvokerCaller = (
	method: string, url: string, body: any = undefined, headers: Headers, controller: AbortController
): Promise<Response> => {
	// console.log(url);
	// headers.forEach((value: string, key: string) => { console.log(key, value); });

	const timeout: number = window.setTimeout((): void => {
		controller.abort(new Error("z5pZpeYegr", 408, "timeout", "Request timeout"));
	}, TIMEOUT);

	const request_init: RequestInit = {
		method: method,
		headers: headers,
		signal: controller.signal,
		credentials: "include"
	};

	if (body) request_init.body = body;

	return fetch(url, request_init).finally((): void => {
		window.clearTimeout(timeout);
	});
};



const InvokerResponseHandler = <T>(
	invoker: Promise<Response>, resolve: (response_body: T) => void, reject: (error: Error) => void
): void => {
	invoker.then((response: Response): void => {
		// console.log(response);
		// response.headers.forEach((value: string, key: string) => { console.log(key, value); });

		if (response.status === 204) {
			resolve({} as T);
			return;
		} else if (response.headers.has("Content-Type")) {
			if (response.headers.get("Content-Type")?.includes("application/json")) {
				response.json().then((value: any): void => {
					// console.log(value);

					try {
						resolve(ResponseHandlerWithData(response.status, value));
					} catch (err: any) {
						reject(err);
					}

				}).catch((/* reason: any */): void => {
					reject(new Error("4MtcvtT6FI"));
				});
				return;
			}
		}

		try {
			resolve(ResponseHandlerWithData(response.status, {}));
		} catch (err: any) {
			reject(err);
		}

		reject(new Error("7pj7jgrmwX"));
	}).catch((error: any): void => {
		// console.log(error);

		if (error instanceof Error) {
			reject(error);
			return;
		}

		reject(new Error("sRM1UtlD6I"));
	});
};

const ResponseHandlerWithData = (status: number, data: any) => {
	if ([ 200, 202 ].includes(status)) {
		return data;
	} else if (("status" in data) && (data["status"] === "error")) { /* Cater for all errors */
		throw Parse_Backend_Error(data);
	} else if ((status === 401) && ("detail" in data)) {
		throw new Error("INUK2y0X4Q", 401, "unauthorized");
	} else if (
		(status === 401) && ("code" in data) && (data["code"] === "token_not_valid")
	) throw new Error("IqYy7vTICE", 401, "access_token_invalid");
	else if (status === 404)
		throw new Error("g8HnwBUocM", 404, "not_found");
	else throw new Error("tqHX2paypJ");
};





export const Invoker = <T>(
	parameters: InvokerParameters, authorisation: boolean = false, controller?: AbortController
): Promise<T> => {
	return new Promise<T>((
		resolve: (value: T) => void, reject: (reason: Error) => void
	): void => {
		const headers: Headers = new Headers();
		headers.append("Accept", "application/json");
		if (parameters.body && contains(parameters.body))
			headers.append("Content-Type", "application/json; charset=UTF-8");
		if (authorisation) {
			const access_token: string | null = Get_Access_Token();
			if (contains(access_token)) headers.append("Authorization", `Bearer ${access_token}`);
		}

		// console.log(parameters.url);

		InvokerResponseHandler<T>(
			InvokerCaller(
				parameters.method, parameters.url.toString(), JSON.stringify(parameters.body), headers,
				(controller) ? controller : new AbortController()
			),
			(response_body: T): void => { resolve(response_body); },
			(error: Error): void => { reject(error); }
		);
	});
};
