
import { UserSettings } from "src/utils/variables";
import { Error } from "src/utils/classes";
import { APIURL, Invoker } from "src/utils/invoker";





export class Network {

	private GET_UserSettings_ALLOW: boolean = true;
	GET_UserSettings(): Promise<UserSettings> {
		return new Promise<UserSettings>((
			resolve: (value: UserSettings) => void, reject: (reason: Error) => void
		): void => {
			if (!this.GET_UserSettings_ALLOW) return;
			this.GET_UserSettings_ALLOW = false;

			Invoker<Record<string, any>>(
				{ method: "GET", url: APIURL("/user/settings") }, true
			).then((response: Record<string, any>): void => {
				// console.log(response);

				resolve(response as UserSettings);
			}).catch((error: Error): void => {
				// console.log(error);
				reject(error);
			}).finally((): void => {
				this.GET_UserSettings_ALLOW = true;
			});
		});
	}



	private PATCH_UserSettings_ALLOW: boolean = true;
	PATCH_UserSettings(us: UserSettings): Promise<boolean> {
		return new Promise<boolean>((
			resolve: (value: boolean) => void, reject: (reason: Error) => void
		): void => {
			if (!this.PATCH_UserSettings_ALLOW) return;
			this.PATCH_UserSettings_ALLOW = false;

			Invoker<Record<string, any>>(
				{ method: "PATCH", url: APIURL("/user/settings"), body: { settings: us } }, true
			).then((/* response: Record<string, any> */): void => {
				// console.log(response);

				resolve(true);
			}).catch((error: Error): void => {
				// console.log(error);
				reject(error);
			}).finally((): void => {
				this.PATCH_UserSettings_ALLOW = true;
			});
		});
	}



	private DELETE_Logout_ALLOW: boolean = true;
	DELETE_Logout(): Promise<boolean> {
		return new Promise<boolean>((
			resolve: (value: boolean) => void, reject: (reason: Error) => void
		): void => {
			if (!this.DELETE_Logout_ALLOW) return;
			this.DELETE_Logout_ALLOW = false;

			Invoker<Record<string, any>>(
				{ method: "DELETE", url: APIURL("/logout") }, true
			).then((/* response: Record<string, any> */): void => {
				// console.log(response);

				resolve(true);
			}).catch((error: Error): void => {
				// console.log(error);
				reject(error);
			}).finally((): void => {
				this.DELETE_Logout_ALLOW = true;
			});
		});
	}
}
