
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";

import App from "./App";





declare global {
	interface Document {
		approot: HTMLElement;
	}

	namespace JSX {
		interface IntrinsicElements {

			/*
				----- Global Elements -----
			*/

			"availabilities-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			"button-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			"content-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"content-list-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Input Container
			"input-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"input-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"place-holder": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			"loading-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			"message-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- Components -----
			*/

			"tab-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Content Container

			"content-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"prcontent-info-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"prcontent-info": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"buttons-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Content Page

			"content-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"rating-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Filter Settings

			"filter-settings": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Footer Bar

			"footer-full-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"footer-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Mobile App Ad

			"mobile-app-ad": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Multiple Tabs

			"multiple-tabs": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Navigation Bar

			"navigation-full-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"navigation-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"link-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"search-result": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Production Page

			"production-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Radio Button

			"radio-button": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Revenue Container

			"revenue-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Single Tabs

			"single-tabs": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// YouTube Video Player

			"youtube-video-player": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"button-play-pause": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"button-fullscreen": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"seek-bar-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"seek-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;





			/*
				----- Modals -----
			*/

			"share-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// AdBlock

			"adblock-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;





			/*
				----- 404 Page -----
			*/

			"page-404": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- About Us Page -----
			*/

			"about-us-page-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- Contact Us Page -----
			*/

			"contact-us-page-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- Explore Page -----
			*/

			"explore-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"help-info": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"filter-settings-bar": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"main-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- Home Page -----
			*/

			"home-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"welcome-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- Login Page -----
			*/

			"login-page-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			"password-update-box": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"forgot-your-password-box": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"login-box": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Signup

			"nav-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"signup-box": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"slide-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- PRContent Page -----
			*/

			"prcontent-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- Privacy Policy Page -----
			*/

			"privacy-policy-page-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;


			/*
				----- Spotlight Page -----
			*/

			"spotlight-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"content-blocks-list": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- User Page -----
			*/

			// Components

			"content-following": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"production-following": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"production-following-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"user-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Content Group

			"group-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"group-block": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
			"content-save-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// PRContent Group

			"prcontent-save-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Watched Content

			"watched-content-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;



			/*
				----- User Setting Page -----
			*/

			"user-setting-page": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// General

			"general-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Edit Profile

			"edit-profile-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Change Password

			"change-password-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

			// Help

			"help-container": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

		}
	}
}





document.approot = document.body.querySelector("app-root") as HTMLElement;

createRoot(document.approot).render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
